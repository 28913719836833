//调用阿里云OSS
import { local } from "./util";
import axios from "./api";
export let tengxuncloud = (response) => {
  return new Promise((resolve, reject) => {
    // const that = this;
    // console.log(file);
    // axios.post('/pc/file/common', file.file).then(res => {
    //   console.log(res.data, '修改');
    // })
    const file = response.file;
    const fileFormData = new FormData();
    fileFormData.append("file", file);
    fileFormData.append("company_id", 1117);
    fileFormData.append(
      "user_id",
      local.get("user") && local.get("user").user_id
    );
    axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
      if (resp.code != 200) {
        this.$message.error("图片上传失败，请重新上传");
      } else {
        resolve(resp);
        // console.log(resp.url, 123321);
        // this.getcart1(resp.url);
        // this.$emit("closeTip1", resp.url);
      }
    }).catch(error => {

    });
    //   async function multipartUpload() {
    //     let temporary = file.file.name.lastIndexOf(".");
    //     let fileNameLength = file.file.name.length;
    //     let fileFormat = file.file.name.substring(
    //       temporary + 1,
    //       fileNameLength
    //     );
    //     let fileName = getFileNameUUID() + "." + fileFormat;
    //     client.multipartUpload(`${fileName}`, file.file, {
    //       progress: function (p) {
    //       }
    //     })
    //       .then((data) => {
    //         resolve(data)
    //       })
    //       .catch(err => {
    //       });
    //   }
    //   multipartUpload();

  })
}
